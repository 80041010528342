<template>
  <div class="proposal-content">
    <div class="proposal-main">
      <user-card :obj="{ avatar: avatar, name: userName, job: jobName }">
        <template #tip>
          <span class="instructions" @click="showDialog = true">填写说明</span>
        </template>
      </user-card>
      <el-form :model="form" :show-message="true" inline-message ref="ruleForm" label-width="80px" label-position="top" v-if="formType.length > 0" class="reset-form">
        <div style="margin-top:10px;">
          <!-- 遍历 -->
          <div v-for="(item, index) in formType" :key="index">
            <!-- 提案时间 -->
            <div class="form-list pre" v-if="item.formCode == 4 && item.isEnabled != 0">
              <el-form-item
                prop="proposalDate"
                :rules="{
                  required: item.isMust,
                  message: `${item.showName}不能为空`,
                  trigger: 'change'
                }"
                :label="item.showName || '提案日期'"
              >
                <el-date-picker :disabled="!!item.isOperation" type="date" placeholder="请选择" v-model="form.proposalDate" value-format="yyyy-MM-dd" :picker-options="pickerOptions"></el-date-picker>
              </el-form-item>
            </div>
            <!-- 标题 -->
            <div class="form-list pre" v-if="item.formCode == 5 && item.isEnabled != 0">
              <el-form-item
                :rules="[
                  {
                    required: item.isMust,
                    message: `${item.showName}不能为空`,
                    trigger: 'blur',
                    validator: item.isMust ? $checkTrim : ''
                  },
                  { min: 1, max: 50, message: `${item.showName}限50字之内`, trigger: 'blur' }
                ]"
                :label="item.showName || '标题'"
                prop="title"
              >
                <el-input v-filteremoji type="textarea" :autosize="{ minRows: 1, maxRows: 3 }" v-model="form.title" placeholder="请输入" maxlength="50" show-word-limit resize="none"></el-input>
              </el-form-item>
            </div>
            <!-- 实施单位 -->
            <div class="form-list pre" v-if="item.formCode == 1 && item.isEnabled != 0">
              <el-form-item
                :label="item.showName || '实施单位'"
                :rules="{
                  required: item.isMust,
                  message: `${item.showName}不能为空`,
                  trigger: 'change'
                }"
                prop="implementOrgId"
              >
                <el-select v-model="form.implementOrgId" filterable remote placeholder="请选择" :remote-method="remoteMethod" clearable class="org-sel" @change="handleOrgName">
                  <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgChain" :value="item.orgId"> </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- 创新维度 -->
            <div class="form-list pre" v-if="item.formCode == 2 && item.isEnabled != 0">
              <el-form-item
                prop="innovateLevelName"
                :rules="{
                  required: item.isMust,
                  message: `${item.showName}不能为空`,
                  trigger: 'change'
                }"
                :label="item.showName || '创新维度'"
              >
                <el-select v-model="form.innovateLevelName" placeholder="请选择" @change="handleLevelCode">
                  <el-option v-for="item in beddingList" :key="item.id" :label="item.proposalDesc" :value="item.proposalDesc"> </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- 创新类型 -->
            <div class="form-list pre" v-if="item.formCode == 3 && item.isEnabled != 0">
              <el-form-item
                prop="innovateCategoryName"
                :rules="{
                  required: item.isMust,
                  message: `${item.showName}不能为空`,
                  trigger: 'change'
                }"
                :label="item.showName || '提案类别'"
              >
                <el-select v-model="form.innovateCategoryName" placeholder="请选择" @change="handlCategoryCode">
                  <el-option v-for="item in category" :key="item.id" :label="item.proposalDesc" :value="item.proposalDesc"> </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- 实施步骤 -->
            <div class="form-list pre" v-if="item.formCode == 9 && item.isEnabled != 0">
              <el-form-item
                :label="item.showName || '实施步骤'"
                :rules="[
                  {
                    required: item.isMust,
                    message: `${item.showName}不能为空`,
                    trigger: 'blur',
                    validator: item.isMust ? $checkTrim : ''
                  },
                  {
                    min: 1,
                    max: 500,
                    message: `${item.showName}限500字之内`,
                    trigger: 'blur'
                  }
                ]"
                prop="implementContent"
              >
                <el-input
                  v-filteremoji
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 10 }"
                  maxlength="500"
                  show-word-limit
                  v-model="form.implementContent"
                  :placeholder="!item.defaultValue ? item.tips : '请详述: 1、前期先状/问题描述; 2:优化/升级/方案'"
                  resize="none"
                ></el-input>
              </el-form-item>
            </div>
            <!-- 要点 -->
            <div class="form-list pre" v-if="item.formCode == 6 && item.isEnabled != 0">
              <el-form-item
                :label="item.showName || '要点'"
                :rules="[
                  {
                    required: item.isMust,
                    message: `${item.showName}不能为空`,
                    trigger: 'blur',
                    validator: item.isMust ? $checkTrim : ''
                  },
                  {
                    min: 1,
                    max: 500,
                    message: `${item.showName}限500字之内`,
                    trigger: 'blur'
                  }
                ]"
                prop="gist"
              >
                <el-input v-filteremoji type="textarea" :autosize="{ minRows: 1, maxRows: 10 }" maxlength="500" show-word-limit v-model="form.gist" placeholder="请输入" resize="none"></el-input>
              </el-form-item>
            </div>
            <!-- 目前现状 -->
            <div class="form-list pre" v-if="item.formCode == 7 && item.isEnabled != 0">
              <el-form-item
                :label="item.showName || '目前现状'"
                :rules="[
                  {
                    required: item.isMust,
                    message: `${item.showName}不能为空`,
                    trigger: 'blur',
                    validator: item.isMust ? $checkTrim : ''
                  },
                  {
                    min: 1,
                    max: 500,
                    message: `${item.showName}限500字之内`,
                    trigger: 'blur'
                  }
                ]"
                prop="currentSituation"
              >
                <el-input
                  v-filteremoji
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 10 }"
                  maxlength="500"
                  show-word-limit
                  v-model="form.currentSituation"
                  placeholder="请输入"
                  resize="none"
                ></el-input>
              </el-form-item>
            </div>
            <!-- 内容 -->
            <div class="form-list pre" v-if="item.formCode == 8 && item.isEnabled != 0">
              <el-form-item
                :label="item.showName || '创新具体内容'"
                :rules="[
                  {
                    required: item.isMust,
                    message: `${item.showName}不能为空`,
                    trigger: 'blur',
                    validator: item.isMust ? $checkTrim : ''
                  },
                  {
                    min: 1,
                    max: 3000,
                    message: `${item.showName}限3000字之内`,
                    trigger: 'blur'
                  }
                ]"
                prop="innovateContent"
              >
                <el-input
                  v-filteremoji
                  type="textarea"
                  v-model="form.innovateContent"
                  :autosize="{ minRows: 1, maxRows: 40 }"
                  placeholder="请输入"
                  maxlength="3000"
                  show-word-limit
                  resize="none"
                ></el-input>
              </el-form-item>
            </div>
            <!-- 预测结果 -->
            <div class="form-list pre" v-if="item.formCode == 10 && item.isEnabled != 0">
              <el-form-item
                :label="item.showName || '预测取得成果'"
                :rules="[
                  {
                    required: item.isMust,
                    message: `${item.showName}不能为空`,
                    trigger: 'blur',
                    validator: item.isMust ? $checkTrim : ''
                  },
                  {
                    min: 1,
                    max: 500,
                    message: `${item.showName}限500字之内`,
                    trigger: 'blur'
                  }
                ]"
                prop="forecastResult"
              >
                <el-input
                  v-filteremoji
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 10 }"
                  maxlength="500"
                  show-word-limit
                  v-model="form.forecastResult"
                  placeholder="请输入"
                  resize="none"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-list pre" v-if="item.formCode == 11 && item.isEnabled != 0">
              <div class="item-box">
                <div class="file-box">
                  <div class="file-title" :class="item.isMust ? 'tip' : ''">
                    {{ item.showName || '上传文件' }}
                  </div>
                  <div v-if="filePathList">
                    <tojoy-file
                      class="file-item"
                      :isShow="true"
                      v-for="(file, index) in filePathList"
                      :index="index"
                      :key="index"
                      @remove="handleRemove"
                      :data="{
                        type: file.fileSuffix || file.fileType,
                        fileName: file.fileName || '暂无',
                        url: file.filePath
                      }"
                    />
                  </div>
                </div>
              </div>
              <img-upload :num="filePathList && filePathList.length" uploadType="innovate-annex" :disabled="true" @handleSuccess="handleSuccess" class="upload-box"></img-upload>
            </div>
            <div class="pre pre-color" v-if="index % 2 == 1"></div>
          </div>
        </div>
      </el-form>
    </div>
    <div class="result-bottom">
      <el-button @click="handleSaveDrafts" v-if="type === 'add'" class="white">保存草稿</el-button>
      <el-button type="primary" @click="handleNode" class="blue">提交</el-button>
    </div>
    <approvalExplanation :showDialog="showDialog" :type="1" />
    <approvalNode :display="showNodeDialog" @before-close="handleClose" :id="orgId" :innovateLevel="innovateLevel" :innovateLevelName="form.innovateLevelName" @handleConfirm="handleConfirm" />
  </div>
</template>
<script>
import userCard from '@/components/initiate/user-card'
import approvalExplanation from '@/components/initiate/approval-explanation'
import approvalNode from '@/components/initiate/approval-node'
import { postInnovationEdit, postInnovationAdd, getInnovationBedding, getInnovationCategory, getDataDraft, getOrgList, postDataDraftSave, getInnovationDetail, getFromList } from '@/api/api_innovation'
import TojoyFile from '@/components/common/file'
import ImgUpload from '@/components/common/file/upload.vue'
import { mapState } from 'vuex'
export default {
  name: 'initiate-proposal',
  components: {
    userCard,
    approvalExplanation,
    approvalNode,
    TojoyFile,
    ImgUpload
  },
  props: {
    type: {
      type: String,
      default: 'add'
    },
    id: {
      type: String,
      default: ''
    },
    moduleName: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      formObj: {},
      formIds: {},
      formType: [],
      orgId: 0,
      orgName: '',
      orgList: [],
      beddingList: [],
      category: [],
      showNodeDialog: false,
      showDialog: false,
      processObj: {},
      processExampleDtoList: [], //流程选择
      filePathList: [], //附件列表
      innovateLevel: null,
      innovateCategory: null, //创新应用类别
      form: {
        implementOrgId: null, //实施单位 code 1
        innovateLevelName: '', //创新应用层面  2
        innovateCategoryName: '', //创新应用类别  3
        proposalDate: '', //提案日期  4
        title: '', //标题 5
        gist: '', //要点  6
        currentSituation: '', //目前现状  7
        innovateContent: '', //内容      8
        implementContent: '', //实施步骤  9
        forecastResult: '', //预测取得成果  10
        filePathList: '' //上传文件  11
      },
      rules: {}
    }
  },
  computed: {
    ...mapState({
      avatar: state => state.userInfo.avatar,
      userName: state => state.userInfo.userName,
      jobName: state => (state.userInfo.jobList && state.userInfo.jobList.length > 0 ? state.userInfo.jobList[0].jobName : '')
    })
  },
  methods: {
    /**
     * 动态表单字段
     */
    handleFormList() {
      getFromList()
        .then(res => {
          if (res.data) {
            let keys = Object.keys(this.form)
            res.data.forEach((el, index) => {
              this.$set(this.formType, index, el)
              // 如果返回时间直接赋值
              if (el.formCode == 4 && el.defaultValue) {
                this.form.proposalDate = el.defaultValue
              }
              // 如果是提交文件
              if (11 === el.formCodes) {
                if (el.isEnabled) {
                  this.$set(this.formIds, 'filePathListFormId', el.formId)
                } else {
                  this.$set(this.formIds, 'filePathListFormId', null)
                }
              } else {
                if (keys[el.formCode - 1] === 'innovateLevelName' || keys[el.formCode - 1] === 'innovateCategoryName') {
                  if (el.isEnabled) {
                    this.$set(this.formIds, keys[el.formCode - 1].split('N')[0] + 'FormId', el.formId)
                  } else {
                    this.$set(this.formIds, keys[el.formCode - 1].split('N')[0] + 'FormId', null)
                  }
                } else {
                  if (el.isEnabled) {
                    this.$set(this.formIds, keys[el.formCode - 1] + 'FormId', el.formId)
                  } else {
                    this.$set(this.formIds, keys[el.formCode - 1] + 'FormId', null)
                  }
                }
              }
            })
          } else {
            this.formType = []
          }
        })
        .catch(err => {})
    },
    /**
     * 提案详情
     */
    handleDetail() {
      let params = {
        innovateCode: this.id
      }
      getInnovationDetail(params)
        .then(res => {
          if (res.data) {
            console.log('jjj', res.data)
            //用户信息从哪里取？？？
            //processState 流程状态
            let {
              implementOrgId, //实施单位
              implementOrgName,
              innovateLevel, //创新应用层面
              innovateLevelName,
              innovateCategory, //创新应用类别
              innovateCategoryName,
              proposalDate, //提案日期
              title, //标题
              gist, //要点
              currentSituation, //目前现状
              innovateContent, //内容
              implementContent, //实施步骤
              forecastResult, //预测取得成果
              innovateAnnexList,
              webProcessTemplateOutput
            } = res.data
            this.formObj = res.data
            this.processObj = webProcessTemplateOutput
            this.orgName = implementOrgName || ''
            this.form.implementOrgId = implementOrgId || null
            this.form.innovateLevelName = innovateLevelName || ''
            this.innovateLevel = innovateLevel || null
            this.form.innovateCategoryName = innovateCategoryName || ''
            this.innovateCategory = innovateCategory || null
            this.form.proposalDate = proposalDate || ''
            this.form.title = title || ''
            this.form.gist = gist || ''
            this.form.currentSituation = currentSituation || ''
            this.form.innovateContent = innovateContent || ''
            this.form.implementContent = implementContent || ''
            this.form.forecastResult = forecastResult || ''
            this.filePathList = innovateAnnexList || []
            this.remoteMethod(this.orgName)
            let formkeys = Object.keys(this.form)
            formkeys.push('innovateAnnexList')
            console.log('ooooo', this.filePathList, forecastResult)
            let data = res.data
            for (let key in data) {
              formkeys.forEach((el, index) => {
                if (el === key) {
                  if (el === 'innovateLevelName' || el === 'innovateCategoryName') {
                    el = el.split('N')[0]
                  }
                  let obj = {
                    isEnabled: data[`${el}FormCode`],
                    isMust: data[`${el}FormMust`],
                    showName: data[`${el}FormName`]
                  }
                  if (el === 'innovateAnnexList') {
                    this.$set(this.formIds, 'filePathListFormId', data[`${el}FormCode`])
                  } else {
                    this.$set(this.formIds, `${el}FormId`, data[`${el}FormCode`])
                  }
                  this.$set(this.formType, index, obj)
                }
              })
            }
          } else {
            this.initiateObj = {}
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleSuccess(res, file) {
      let fileSuffix = this.$getFileSuffix(file.name)
      let fileName = this.$splitFileName(file.name)
      let obj = {
        fileSuffix: fileSuffix,
        fileName: fileName,
        filePath: res.list[0]
      }
      this.filePathList.push(obj)
    },
    async handleAdd(params) {
      try {
        if (this.type === 'add') {
          console.log('ssssss', { ...params, ...this.formIds })
          return await postInnovationAdd({ ...params, ...this.formIds })
        } else {
          return await postInnovationEdit({ ...params, innovateCode: this.id })
        }
      } catch (err) {
        return err
      }
    },
    /**
     * 提交
     */
    handleConfirm(processExampleDtoList, processTemplateId, processTemplateName) {
      let { implementOrgId, innovateLevelName, innovateCategoryName, proposalDate, title, gist, currentSituation, innovateContent, implementContent, forecastResult } = this.form
      let params = {
        implementOrgId,
        implementOrgName: this.orgName,
        innovateLevel: this.innovateLevel,
        innovateCategory: this.innovateCategory,
        innovateLevelName,
        innovateCategoryName,
        proposalDate,
        title,
        gist,
        currentSituation,
        innovateContent,
        implementContent,
        forecastResult,
        filePathList: this.filePathList,
        processExampleDtoList,
        processTemplateId: processTemplateId,
        processTemplateName: processTemplateName
      }
      this.loading = true
      this.handleAdd(this.$filterParamsTrim(params))
        .then(res => {
          if (res.code === '000000') {
            this.$msgSuccess('操作成功')
            this.showNodeDialog = false
            this.$router.push({
              name: this.type === 'add' ? 'channel' : 'initiate',
              query: {
                moduleName: this.type === 'edit' ? this.moduleName : null
              }
            })
          }
        })
        .catch(err => {
          console.log('err', err)
          this.$msgError('服务异常')
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * 删除文件
     */
    handleRemove(index, data) {
      this.filePathList.splice(index, 1)
    },
    handleNode() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.formType.forEach(item => {
            // 如果有上传文件
            if (item.formCode === 11) {
              if (item.isEnabled && item.isMust && this.filePathList.length < 1) {
                this.$msgError('上传文件不能为空！')
                return false
              }
            }
          })
          this.showNodeDialog = true
          this.orgId = this.form.implementOrgId
        } else {
          return false
        }
      })
    },
    /**
     * 实施单位搜索
     */
    remoteMethod(val) {
      if (val === '') {
        this.orgList = []
      } else {
        getOrgList({ orgName: val })
          .then(res => {
            let { orgList } = res.data
            this.orgList = orgList
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    handleOrgName(val) {
      if (val) {
        this.orgName = this.orgList.find(el => el.orgId === val)?.orgName
      } else {
        this.orgName = ''
      }
    },
    /**
     * 关闭审批节点弹框
     */
    handleClose(val) {
      this.showNodeDialog = val
    },
    /**
     * 保存草稿箱
     */
    handleSaveDrafts() {
      let { implementOrgId, innovateLevelName, innovateCategoryName, proposalDate, title, gist, currentSituation, innovateContent, implementContent, forecastResult } = this.form

      let params = {
        implementOrgId,
        innovateLevel: this.innovateLevel,
        innovateCategory: this.innovateCategory,
        innovateLevelName,
        innovateCategoryName,
        proposalDate,
        implementOrgName: this.orgName,
        title,
        gist,
        currentSituation,
        innovateContent,
        implementContent,
        forecastResult,
        filePathList: this.filePathList,
        ...this.formIds
      }
      postDataDraftSave(this.$filterParamsTrim(params))
        .then(res => {
          if (res.code === '000000') {
            this.$msgSuccess('操作成功')
            this.getDataDraft()
          }
        })
        .catch(err => {})
    },
    /**
     * 草稿箱回显
     */
    getDataDraft() {
      getDataDraft()
        .then(res => {
          if (!res.data) return false
          let {
            implementOrgId, //实施单位
            implementOrgName,
            innovateLevel, //创新应用层面
            innovateLevelName, //创新应用层面
            innovateCategory, //创新应用类别
            innovateCategoryName, //创新应用类别
            proposalDate, //提案日期
            title, //标题
            gist, //要点
            currentSituation, //目前现状
            innovateContent, //内容
            implementContent, //实施步骤
            forecastResult, //预测取得成果
            filePathList
          } = res.data
          console.log('ssss', res.data)
          let arr = implementOrgName ? implementOrgName.split('/') : []
          this.orgName = (arr.length > 0 && arr[arr.length - 1]) || ''
          this.form.implementOrgId = implementOrgId || null
          this.form.innovateLevelName = innovateLevelName || ''
          this.innovateLevel = innovateLevel || null
          this.form.innovateCategoryName = innovateCategoryName || ''
          this.innovateCategory = innovateCategory || null
          this.form.proposalDate = proposalDate || ''
          this.form.title = title || ''
          this.form.gist = gist || ''
          this.form.currentSituation = currentSituation || ''
          this.form.innovateContent = innovateContent || ''
          this.form.implementContent = implementContent || ''
          this.form.forecastResult = forecastResult || ''
          this.filePathList = filePathList || []
          this.remoteMethod(this.orgName)
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**
     * 创新应用层面列表查询
     */
    getBedding() {
      getInnovationBedding()
        .then(res => {
          //id proposalDesc
          this.beddingList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleLevelCode(val) {
      this.innovateLevel = this.beddingList.find(el => el.proposalDesc === val).id
    },
    /**
     * 创新应用类别
     */
    getCategory() {
      getInnovationCategory()
        .then(res => {
          this.category = res.data
        })
        .catch(err => console.log(err))
    },
    handlCategoryCode(val) {
      this.innovateCategory = this.category.find(el => el.proposalDesc === val).id
    }
  },
  created() {
    this.getCategory()
    this.getBedding()
    if (this.type === 'add') {
      //新增-草稿箱
      this.handleFormList()
      this.getDataDraft()
    } else {
      //编辑-详情
      this.handleDetail()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/addProposal.scss';
.pre {
  margin-top: 0;
  padding-top: 6px;
  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
  }
  &:not(:last-child):focus-within {
    border-color: $blue;
  }
}
.pre-color {
  height: 10px;
}
</style>
