var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"proposal-content"},[_c('div',{staticClass:"proposal-main"},[_c('user-card',{attrs:{"obj":{ avatar: _vm.avatar, name: _vm.userName, job: _vm.jobName }},scopedSlots:_vm._u([{key:"tip",fn:function(){return [_c('span',{staticClass:"instructions",on:{"click":function($event){_vm.showDialog = true}}},[_vm._v("填写说明")])]},proxy:true}])}),(_vm.formType.length > 0)?_c('el-form',{ref:"ruleForm",staticClass:"reset-form",attrs:{"model":_vm.form,"show-message":true,"inline-message":"","label-width":"80px","label-position":"top"}},[_c('div',{staticStyle:{"margin-top":"10px"}},_vm._l((_vm.formType),function(item,index){return _c('div',{key:index},[(item.formCode == 4 && item.isEnabled != 0)?_c('div',{staticClass:"form-list pre"},[_c('el-form-item',{attrs:{"prop":"proposalDate","rules":{
                required: item.isMust,
                message: ((item.showName) + "不能为空"),
                trigger: 'change'
              },"label":item.showName || '提案日期'}},[_c('el-date-picker',{attrs:{"disabled":!!item.isOperation,"type":"date","placeholder":"请选择","value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions},model:{value:(_vm.form.proposalDate),callback:function ($$v) {_vm.$set(_vm.form, "proposalDate", $$v)},expression:"form.proposalDate"}})],1)],1):_vm._e(),(item.formCode == 5 && item.isEnabled != 0)?_c('div',{staticClass:"form-list pre"},[_c('el-form-item',{attrs:{"rules":[
                {
                  required: item.isMust,
                  message: ((item.showName) + "不能为空"),
                  trigger: 'blur',
                  validator: item.isMust ? _vm.$checkTrim : ''
                },
                { min: 1, max: 50, message: ((item.showName) + "限50字之内"), trigger: 'blur' }
              ],"label":item.showName || '标题',"prop":"title"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 3 },"placeholder":"请输入","maxlength":"50","show-word-limit":"","resize":"none"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1)],1):_vm._e(),(item.formCode == 1 && item.isEnabled != 0)?_c('div',{staticClass:"form-list pre"},[_c('el-form-item',{attrs:{"label":item.showName || '实施单位',"rules":{
                required: item.isMust,
                message: ((item.showName) + "不能为空"),
                trigger: 'change'
              },"prop":"implementOrgId"}},[_c('el-select',{staticClass:"org-sel",attrs:{"filterable":"","remote":"","placeholder":"请选择","remote-method":_vm.remoteMethod,"clearable":""},on:{"change":_vm.handleOrgName},model:{value:(_vm.form.implementOrgId),callback:function ($$v) {_vm.$set(_vm.form, "implementOrgId", $$v)},expression:"form.implementOrgId"}},_vm._l((_vm.orgList),function(item){return _c('el-option',{key:item.orgId,attrs:{"label":item.orgChain,"value":item.orgId}})}),1)],1)],1):_vm._e(),(item.formCode == 2 && item.isEnabled != 0)?_c('div',{staticClass:"form-list pre"},[_c('el-form-item',{attrs:{"prop":"innovateLevelName","rules":{
                required: item.isMust,
                message: ((item.showName) + "不能为空"),
                trigger: 'change'
              },"label":item.showName || '创新维度'}},[_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":_vm.handleLevelCode},model:{value:(_vm.form.innovateLevelName),callback:function ($$v) {_vm.$set(_vm.form, "innovateLevelName", $$v)},expression:"form.innovateLevelName"}},_vm._l((_vm.beddingList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.proposalDesc,"value":item.proposalDesc}})}),1)],1)],1):_vm._e(),(item.formCode == 3 && item.isEnabled != 0)?_c('div',{staticClass:"form-list pre"},[_c('el-form-item',{attrs:{"prop":"innovateCategoryName","rules":{
                required: item.isMust,
                message: ((item.showName) + "不能为空"),
                trigger: 'change'
              },"label":item.showName || '提案类别'}},[_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":_vm.handlCategoryCode},model:{value:(_vm.form.innovateCategoryName),callback:function ($$v) {_vm.$set(_vm.form, "innovateCategoryName", $$v)},expression:"form.innovateCategoryName"}},_vm._l((_vm.category),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.proposalDesc,"value":item.proposalDesc}})}),1)],1)],1):_vm._e(),(item.formCode == 9 && item.isEnabled != 0)?_c('div',{staticClass:"form-list pre"},[_c('el-form-item',{attrs:{"label":item.showName || '实施步骤',"rules":[
                {
                  required: item.isMust,
                  message: ((item.showName) + "不能为空"),
                  trigger: 'blur',
                  validator: item.isMust ? _vm.$checkTrim : ''
                },
                {
                  min: 1,
                  max: 500,
                  message: ((item.showName) + "限500字之内"),
                  trigger: 'blur'
                }
              ],"prop":"implementContent"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 10 },"maxlength":"500","show-word-limit":"","placeholder":!item.defaultValue ? item.tips : '请详述: 1、前期先状/问题描述; 2:优化/升级/方案',"resize":"none"},model:{value:(_vm.form.implementContent),callback:function ($$v) {_vm.$set(_vm.form, "implementContent", $$v)},expression:"form.implementContent"}})],1)],1):_vm._e(),(item.formCode == 6 && item.isEnabled != 0)?_c('div',{staticClass:"form-list pre"},[_c('el-form-item',{attrs:{"label":item.showName || '要点',"rules":[
                {
                  required: item.isMust,
                  message: ((item.showName) + "不能为空"),
                  trigger: 'blur',
                  validator: item.isMust ? _vm.$checkTrim : ''
                },
                {
                  min: 1,
                  max: 500,
                  message: ((item.showName) + "限500字之内"),
                  trigger: 'blur'
                }
              ],"prop":"gist"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 10 },"maxlength":"500","show-word-limit":"","placeholder":"请输入","resize":"none"},model:{value:(_vm.form.gist),callback:function ($$v) {_vm.$set(_vm.form, "gist", $$v)},expression:"form.gist"}})],1)],1):_vm._e(),(item.formCode == 7 && item.isEnabled != 0)?_c('div',{staticClass:"form-list pre"},[_c('el-form-item',{attrs:{"label":item.showName || '目前现状',"rules":[
                {
                  required: item.isMust,
                  message: ((item.showName) + "不能为空"),
                  trigger: 'blur',
                  validator: item.isMust ? _vm.$checkTrim : ''
                },
                {
                  min: 1,
                  max: 500,
                  message: ((item.showName) + "限500字之内"),
                  trigger: 'blur'
                }
              ],"prop":"currentSituation"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 10 },"maxlength":"500","show-word-limit":"","placeholder":"请输入","resize":"none"},model:{value:(_vm.form.currentSituation),callback:function ($$v) {_vm.$set(_vm.form, "currentSituation", $$v)},expression:"form.currentSituation"}})],1)],1):_vm._e(),(item.formCode == 8 && item.isEnabled != 0)?_c('div',{staticClass:"form-list pre"},[_c('el-form-item',{attrs:{"label":item.showName || '创新具体内容',"rules":[
                {
                  required: item.isMust,
                  message: ((item.showName) + "不能为空"),
                  trigger: 'blur',
                  validator: item.isMust ? _vm.$checkTrim : ''
                },
                {
                  min: 1,
                  max: 3000,
                  message: ((item.showName) + "限3000字之内"),
                  trigger: 'blur'
                }
              ],"prop":"innovateContent"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 40 },"placeholder":"请输入","maxlength":"3000","show-word-limit":"","resize":"none"},model:{value:(_vm.form.innovateContent),callback:function ($$v) {_vm.$set(_vm.form, "innovateContent", $$v)},expression:"form.innovateContent"}})],1)],1):_vm._e(),(item.formCode == 10 && item.isEnabled != 0)?_c('div',{staticClass:"form-list pre"},[_c('el-form-item',{attrs:{"label":item.showName || '预测取得成果',"rules":[
                {
                  required: item.isMust,
                  message: ((item.showName) + "不能为空"),
                  trigger: 'blur',
                  validator: item.isMust ? _vm.$checkTrim : ''
                },
                {
                  min: 1,
                  max: 500,
                  message: ((item.showName) + "限500字之内"),
                  trigger: 'blur'
                }
              ],"prop":"forecastResult"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 10 },"maxlength":"500","show-word-limit":"","placeholder":"请输入","resize":"none"},model:{value:(_vm.form.forecastResult),callback:function ($$v) {_vm.$set(_vm.form, "forecastResult", $$v)},expression:"form.forecastResult"}})],1)],1):_vm._e(),(item.formCode == 11 && item.isEnabled != 0)?_c('div',{staticClass:"form-list pre"},[_c('div',{staticClass:"item-box"},[_c('div',{staticClass:"file-box"},[_c('div',{staticClass:"file-title",class:item.isMust ? 'tip' : ''},[_vm._v(" "+_vm._s(item.showName || '上传文件')+" ")]),(_vm.filePathList)?_c('div',_vm._l((_vm.filePathList),function(file,index){return _c('tojoy-file',{key:index,staticClass:"file-item",attrs:{"isShow":true,"index":index,"data":{
                      type: file.fileSuffix || file.fileType,
                      fileName: file.fileName || '暂无',
                      url: file.filePath
                    }},on:{"remove":_vm.handleRemove}})}),1):_vm._e()])]),_c('img-upload',{staticClass:"upload-box",attrs:{"num":_vm.filePathList && _vm.filePathList.length,"uploadType":"innovate-annex","disabled":true},on:{"handleSuccess":_vm.handleSuccess}})],1):_vm._e(),(index % 2 == 1)?_c('div',{staticClass:"pre pre-color"}):_vm._e()])}),0)]):_vm._e()],1),_c('div',{staticClass:"result-bottom"},[(_vm.type === 'add')?_c('el-button',{staticClass:"white",on:{"click":_vm.handleSaveDrafts}},[_vm._v("保存草稿")]):_vm._e(),_c('el-button',{staticClass:"blue",attrs:{"type":"primary"},on:{"click":_vm.handleNode}},[_vm._v("提交")])],1),_c('approvalExplanation',{attrs:{"showDialog":_vm.showDialog,"type":1}}),_c('approvalNode',{attrs:{"display":_vm.showNodeDialog,"id":_vm.orgId,"innovateLevel":_vm.innovateLevel,"innovateLevelName":_vm.form.innovateLevelName},on:{"before-close":_vm.handleClose,"handleConfirm":_vm.handleConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }